export function initSignOut() {

  const signOutButton = document.querySelector('#sign_out');
  if (signOutButton) {  // Check if the element exists
    signOutButton.addEventListener('click', function(e) {
      e.preventDefault();
      fetch('/users/sign_out', { method: 'DELETE', headers: { 'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content } })
        .then(response => {
          if (response.ok) {
            window.location = '/users/sign_in';  // Force reload to sign-in page
          }
        })
        .catch(error => console.error('Error signing out:', error));
    });
  }
}